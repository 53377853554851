<template>
  <div class="OnboardingStep3">
    <div class="title has-text-centered p-v-l is-4">
      {{ $t(`pages.onboarding.step_3.title`) }}
    </div>

    <div class="columns">
      <div class="column">
        <form-field
          :validator="validator.firstName"
          :label="$t('forms.first_name')"
        >
          <input
            v-model.trim="firstName"
            class="input"
            type="text"
            name="firstName"
            placeholder="Casper"
            data-testid="OnboardingStep3_InputFirstName"
            @input="validator.firstName.$touch()"
          >
        </form-field>
      </div>

      <div class="column">
        <form-field
          :validator="validator.lastName"
          :label="$t('forms.last_name')"
        >
          <input
            v-model.trim="lastName"
            class="input"
            type="text"
            name="lastName"
            placeholder="Janns"
            data-testid="OnboardingStep3_InputLastName"
            @input="validator.lastName.$touch()"
          >
        </form-field>
      </div>
    </div>

    <form-field
      :validator="validator.contactRole"
      :label="$t('forms.role')"
    >
      <input
        v-model.trim="contactRole"
        class="input"
        type="text"
        name="contactRole"
        placeholder="CEO"
        data-testid="OnboardingStep3_InputContactRole"
        @input="validator.contactRole.$touch()"
      >
    </form-field>

    <form-field
      :validator="validator.phone"
      :label="$t('general.phone_number')"
    >
      <input
        v-model.trim="phone"
        class="input"
        type="text"
        name="contactPhone"
        placeholder="+45 31 15 24 24"
        data-testid="OnboardingStep3_InputContactPhone"
        @input="validator.phone.$touch()"
      >
    </form-field>

    <el-checkbox
      v-model="isDataPublic"
      class="is-grey can-wrap-label"
      data-testid="OnboardingStep3_isPublicCheckbox"
    >
      {{ $t('pages.onboarding.step_3.make_info_public') }}
    </el-checkbox>

    <div class="buttons no-wrap is-aligned-end OnboardingStep__actions">
      <v-button
        data-testid="OnboardingStep3_BackButton"
        class="is-dark is-width-full-mobile"
        @click="goPrev"
      >
        {{ $t('general.prev') }}
      </v-button>

      <v-button
        :loading="isFinishing"
        data-testid="OnboardingStep3_FinishButton"
        class="is-primary is-width-full-mobile"
        @click="finish"
      >
        {{ $t('general.finish') }}
      </v-button>
    </div>
  </div>
</template>

<script>
import OnboardingStepPrototype, {
  createComputedFormField
} from '../OnboardingStepPrototype'
import { OnboardingApiService } from '@/services/OnboardingApiService'

export default {
  name: 'OnboardingStep3',

  extends: OnboardingStepPrototype,

  data () {
    return {
      isFinishing: false,
      isDataPublic: true
    }
  },

  computed: {
    firstName: createComputedFormField('firstName'),
    lastName: createComputedFormField('lastName'),
    contactRole: createComputedFormField('contactRole'),
    phone: createComputedFormField('phone')
  },

  mounted () {
    /* hack to fix problem with checkbox not being reactive */
    this.$forceUpdate()
  },

  methods: {
    async finish () {
      if (!this.validate()) {
        return
      }

      this.isFinishing = true

      try {
        const payload = {
          user_id: this.value.userId,
          newsroom_name: this.value.newsroomName,

          first_name: this.value.firstName,
          last_name: this.value.lastName,
          website: this.value.website,
          country_iso2: this.value.countryIso2,
          language: this.value.language,

          timezone: this.value.timezone,

          logo: this.value.newsroomLogo,
          address: this.value.address,

          twitter_handle: this.value.twitterHandle,
          facebook_handle: this.value.facebookHandle,
          role: this.value.contactRole,
          phone: this.value.phone
        }

        payload.contact = {
          name: 'Unknown',
          phone: this.value.contactPhone,
          email: this.value.contactEmail
        }

        if (this.isDataPublic) {
          payload.contact = {
            name: `${this.value.firstName} ${this.value.lastName}`,
            phone: this.value.phone,
            email: this.value.contactEmail,
            role: this.value.contactRole
          }
        }

        await OnboardingApiService.registerNewsroom(payload)

        this.goNext()
      } catch (error) {
        this.$displayRequestError(error)
      }

      this.isFinishing = false
    }
  }
}
</script>
